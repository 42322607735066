<template>
  <v-container fluid>
    <CoreReply />
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import CoreReply from '@/components/CoreReply.vue'

export default defineComponent({
  components: { CoreReply },
})
</script>
