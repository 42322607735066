<template>
  <div v-if="mounted">
    <v-card-title>
      추천답변
      <v-spacer />
      <v-btn outlined to="/reply/create">
        <v-icon left>mdi-plus</v-icon>
        등록
      </v-btn>
    </v-card-title>

    <v-card outlined class="mb-3">
      <v-card-text>
        <LabelInput v-model="depth" :cols="2" />
      </v-card-text>
    </v-card>

    <v-card outlined>
      <v-data-table
        v-model="req.selected"
        :headers="base.headers"
        :items="res.recommendAnswers"
        :items-per-page="req.offset"
        sort-by="id"
        sort-desc
        show-select
        hide-default-footer
        @click:row="(row) => $router.push(`${$route.path}/${row.id}`)"
      >
        <template v-slot:item.stamp="{ item }">
          <question-tag :tags="item.stamp" />
        </template>
        <template v-slot:item.invisibleStamp="{ item }">
          <QuestionInvisibleTag small :tags="item.invisibleStamp.map((_) => _.name)" />
        </template>
        <template v-slot:item.title="{ item }">
          <span>{{ item.title }}</span>
        </template>
        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-btn icon class="mr-3" @click="deleteCoreReply">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
            <v-col cols="1">
              <v-select v-model="req.offset" :items="[5, 10, 15, 25, 50, 100]" outlined dense hide-details @change="getListCoreReply" />
            </v-col>
            <v-spacer />
            <v-col>
              <v-pagination v-model="req.pageNumber" total-visible="13" :length="res.pageCount" circle color="#ffd600" @input="getListCoreReply" />
            </v-col>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from '@vue/composition-api'
import { preview } from '@/helper'
import QuestionTag from '@/components/QuestionTag.vue'
import QuestionInvisibleTag from '@/components/QuestionInvisibleTag.vue'
import LabelInput from '@/components/input/Label.vue'

export default defineComponent({
  components: {
    QuestionTag,
    QuestionInvisibleTag,
    LabelInput,
  },

  setup(props, { root }) {
    const state = reactive({
      mounted: false,
      select: [{ model: 'firstDepth' }, { model: 'secondDepth', disabled: 'firstDepth' }, { model: 'thirdDepth', disabled: 'secondDepth' }],
      base: {
        headers: [
          { text: 'ID', value: 'id' },
          { text: '라벨', value: 'stamp' },
          { text: '제목', value: 'title' },
          { text: '작성멘토', value: 'author.name' },
          { text: '선택횟수', value: 'selectCount' },
          { text: '좋아요', value: 'likeCount' },
          { text: '싫어요', value: 'unlikeCount' },
          { text: '투명라벨', value: 'invisibleStamp' },
        ],
      },
      res: {
        recommendAnswers: [],
        firstDepth: [],
        secondDepth: [],
        thirdDepth: [],
        pageCount: null,
      },
      req: {
        selected: [],
        offset: sessionStorage.getItem('recommendAnswerList/offset') ? Number(sessionStorage.getItem('recommendAnswerList/offset')) : 15,
        pageNumber: sessionStorage.getItem('recommendAnswerList/pageNumber') ? Number(sessionStorage.getItem('recommendAnswerList/pageNumber')) : 1,
      },
      depth: {
        firstDepth: sessionStorage.getItem('recommendAnswerList/firstDepth'),
        secondDepth: sessionStorage.getItem('recommendAnswerList/secondDepth'),
        thirdDepth: sessionStorage.getItem('recommendAnswerList/thirdDepth'),
      },
    })

    const getListCoreReply = async () => {
      try {
        sessionStorage.setItem('recommendAnswer/depth', JSON.stringify(state.depth))
        state.req.selected = []

        const coreReplyList = await root.$store.dispatch('coreReply/getListCoreReply', {
          offset: state.req.offset,
          pageNumber: state.req.pageNumber,
          firstDepth: state.depth.firstDepth ?? undefined,
          secondDepth: state.depth.secondDepth ?? undefined,
          thirdDepth: state.depth.thirdDepth ?? undefined,
        })
        state.res.recommendAnswers = coreReplyList.data
        state.res.pageCount = Math.ceil(coreReplyList.totalCount / state.req.offset)
        state.mounted = true
      } catch (err) {
        alert(err)
        console.error(err)
      }
    }

    const deleteCoreReply = async () => {
      if (!confirm(`${state.req.selected.length}개의 추천답변을 삭제합니다. 추천답변 삭제 후 되돌릴 수 없습니다.`)) return

      try {
        for (const e of state.req.selected) {
          await root.$store.dispatch('coreReply/deleteCoreReply', {
            id: Number(e.id),
          })
        }

        alert(`추천답변 ${state.req.selected.length}개가 정상적으로 삭제되었습니다.`)
        state.req.selected = []
        getListCoreReply()
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    watch(
      () => state.depth.firstDepth,
      () => {
        state.depth.secondDepth = null
        state.depth.thirdDepth = null
        if (state.depth.firstDepth) {
          sessionStorage.setItem('recommendAnswerList/firstDepth', state.depth.firstDepth)
        } else {
          sessionStorage.removeItem('recommendAnswerList/firstDepth')
        }
        getListCoreReply()
        state.req.pageNumber = 1
      },
    )

    watch(
      () => state.depth.secondDepth,
      () => {
        state.depth.thirdDepth = null
        if (state.depth.secondDepth) {
          sessionStorage.setItem('recommendAnswerList/secondDepth', state.depth.secondDepth)
        } else {
          sessionStorage.removeItem('recommendAnswerList/secondDepth')
        }
        getListCoreReply()
        state.req.pageNumber = 1
      },
    )

    watch(
      () => state.depth.thirdDepth,
      () => {
        state.depth.thirdDepth
          ? sessionStorage.setItem('recommendAnswerList/thirdDepth', state.depth.thirdDepth)
          : sessionStorage.removeItem('recommendAnswerList/thirdDepth')
        getListCoreReply()
        state.req.pageNumber = 1
      },
    )

    watch(
      () => state.req.offset,
      () => {
        state.req.pageNumber = 1
        sessionStorage.setItem('recommendAnswerList/offset', state.req.offset)
      },
    )

    watch(
      () => state.req.pageNumber,
      () => {
        sessionStorage.setItem('recommendAnswerList/pageNumber', state.req.pageNumber)
      },
    )

    onBeforeMount(async () => {
      getListCoreReply()
    })

    return {
      ...toRefs(state),
      getListCoreReply,
      deleteCoreReply,
      preview,
    }
  },
})
</script>
